import { apikeyBugsnagDev } from "../index";
import type { IEnvConfig } from "@aspen/model";

const protocol = "https:";
const staticHost = `${protocol}//static.dev.test-aspendigital.co/`;

export const devConfig: IEnvConfig = {
  env: "dev",
  host: {
    // dev: "http://47.241.210.92:19090/",
    // prod: "https://api.dev.test-aspendigital.co/"
    dev: `${protocol}//api2.dev.test-aspendigital.co/`,
    prod: `${protocol}//api2.dev.test-aspendigital.co/`
  },
  klineChartUrl: `${staticHost}klinechart/index.html`,
  tvChartUrl: "https://tradingview-nextjs-javascript-git-dev-aspendigital.vercel.app",
  websocket: {
    host: "wss://ws.test-aspendigital.co:443"
  },
  apiKeyBugsnag: apikeyBugsnagDev,
  webDomain: "https://portal.dev.test-aspendigital.co",
  appOnlyDomain: "https://apponly.dev.test-aspendigital.co",
  domainUriPrefix: "https://dev-aspendigital.onelink.me/lUeN",
  fallbackUrl: {
    android: "https://www.pgyer.com/s0Ir",
    ios: "https://www.pgyer.com/AeCi"
  },
  packageName: {
    android: "com.dev.aspen",
    ios: "org.evg.dev.aspen"
  },
  minimumVersion: {
    android: "1",
    ios: "1.3.2"
  },
  operationServer: "http://47.241.210.92:8080/",
  saasServer: "https://api.dev-asportal.co/",
  appScheme: "DevAspen"
};
