import React from "react";
import { i18nUtil, ONLY_MOBILE, BRAND, SUPPORT_APP_SHARE_PATH, HEAP_CONFIG_ID } from "@aspen/libs";

// 可以在此配置多语言的head部分
export const getPageHeader = ({ routeConfig, pathname }) => {
  const intl = i18nUtil.t();
  const common = [
    <title key="1">{BRAND}</title>,
    <link key="3" rel="icon" href="/favicon.ico" />,
    <meta key="10" name="keywords" content="Aspen Digital, Digital Asset, Crypto " />,
    <meta key="13" name="theme-color" content="#000000" />,
    <link key="16" rel="apple-touch-icon" href="/logo192.png" />,
    // <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png" />,
    // <link rel="apple-touch-icon" sizes="152x152" href="/icons/touch-icon-ipad.png" />,
    // <link rel="apple-touch-icon" sizes="180x180" href="/icons/touch-icon-iphone-retina.png" />,
    // <link rel="apple-touch-icon" sizes="167x167" href="/icons/touch-icon-ipad-retina.png" />,
    <link key="17" rel="manifest" href="/manifest.json" />,
    <meta key="14" name="title" property="og:title" content={BRAND} />,
    <meta
      key="15"
      name="description"
      property="og:description"
      content={intl["aspen_meta_description"]}
    />,
    <meta key="2" charSet="utf-8" />,
    ONLY_MOBILE && SUPPORT_APP_SHARE_PATH?.some((path) => location.pathname.includes(path)) ? (
      <meta
        key="8"
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
    ) : (
      <meta key="8" name="viewport" content="user-scalable=yes" />
    ),
    <meta key="9" name="renderer" content="webkit" />,
    <meta key="11" httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />,
    <meta key="12" httpEquiv="Cache-Control" content="no-siteapp" />,
    <meta key="application-name" name="application-name" content={BRAND} />,
    <meta key="apple-mobile-web-app-capable" name="apple-mobile-web-app-capable" content="yes" />,
    // 授权google search console
    <meta
      key="18"
      name="google-site-verification"
      content="iU1xcahdykeEk7BRRy-GGqk1EqJWRmNvj2AzJK9k7H8"
    />,
    // fix Mixed Content error,暂时用不到
    // <meta key="14" httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" />,
    // Use <link rel="preload" as="font"> to fetch your font files earlier
    <link key="4" rel="preload" href="/fonts/DINPro-Regular.otf" as="font" type="font/otf" />,
    <link key="5" rel="preload" href="/fonts/DINPro-Medium.otf" as="font" type="font/otf" />,
    <link key="6" rel="preload" href="/fonts/DINPro-Light.otf" as="font" type="font/otf" />,
    <link key="7" rel="preload" href="/fonts/DINPro-Bold.otf" as="font" type="font/otf" />,
    <script
      key="heap"
      dangerouslySetInnerHTML={{
        __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load(${HEAP_CONFIG_ID});
      `
      }}
    />
  ];

  const getTitle = (title: string, desc: string) => {
    return [
      <title key="title">{`${BRAND} - ${intl[title]}`}</title>,
      <meta key="title1" name="title" property="og:title" content={intl[title]} />,
      <meta
        key="description"
        name="description"
        property="og:description"
        content={desc ? intl["desc"] : intl["aspen_meta_description"]}
      />
    ];
  };

  // 处理动态路由 拿到的pathname是xxx/[id],导致取不到对应的路由
  const key = pathname?.replace("/[id]", "");
  // title取routerConfig的titleKey，trade和资讯新闻详情除外
  const routerTitleKey = routeConfig[key]?.titleKey;
  const routerTitleDesc = routeConfig[key]?.titleDescription ?? "";
  return [...common, routerTitleKey && getTitle(routerTitleKey, routerTitleDesc)];
};
